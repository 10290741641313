.react-color-picker,
.react-color-picker__saturation-spectrum,
.react-color-picker__hue-spectrum {
  box-sizing: border-box; }
  .react-color-picker *,
  .react-color-picker *:before,
  .react-color-picker *:after,
  .react-color-picker__saturation-spectrum *,
  .react-color-picker__saturation-spectrum *:before,
  .react-color-picker__saturation-spectrum *:after,
  .react-color-picker__hue-spectrum *,
  .react-color-picker__hue-spectrum *:before,
  .react-color-picker__hue-spectrum *:after {
    box-sizing: border-box; }

.react-color-picker__saturation-spectrum,
.react-color-picker__hue-spectrum {
  position: relative;
  display: inline-block; }

.react-color-picker__saturation-white,
.react-color-picker__saturation-black {
  position: relative;
  width: 100%;
  height: 100%; }

.react-color-picker__saturation-white {
  background: -webkit-linear-gradient(left, #fff, rgba(204, 154, 129, 0));
  background: linear-gradient(to right, #fff, rgba(204, 154, 129, 0)); }

.react-color-picker__saturation-black {
  background: -webkit-linear-gradient(bottom, #000, rgba(204, 154, 129, 0));
  background: linear-gradient(to top, #000, rgba(204, 154, 129, 0)); }

.react-color-picker__saturation-spectrum {
  cursor: pointer; }

.react-color-picker__saturation-drag {
  display: none;
  border: 1px solid white;
  border-radius: 10px;
  position: absolute;
  top: 0px;
  left: 0px; }
  .react-color-picker__saturation-drag .react-color-picker__saturation-inner,
  .react-color-picker__saturation-drag .react-color-picker__hue-inner {
    position: relative;
    width: 100%;
    height: 100%;
    border: 1px solid black;
    border-radius: 10px; }

.react-color-picker__hue-spectrum {
  background: -webkit-linear-gradient(top, #f00 0%, #ff0 17%, #0f0 33%, #0ff 50%, #00f 67%, #f0f 83%, #f00 100%);
  background: linear-gradient(to bottom, #f00 0%, #ff0 17%, #0f0 33%, #0ff 50%, #00f 67%, #f0f 83%, #f00 100%);
  cursor: pointer; }
  .react-color-picker__hue-spectrum .react-color-picker__hue-drag {
    display: none;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    border: 1px solid #000; }

.react-color-picker--theme-default {
  color: inherit; }
  .react-color-picker--theme-default .react-color-picker__hue-spectrum {
    margin-left: 10px; }
